/* eslint-disable */
import { apiCall } from './client.js';
import axios from 'axios';

const w = window;
const indexedDB = w.indexedDB || w.mozIndexedDB || w.webkitIndexedDB || w.msIndexedDB || w.shimIndexedDB;
let open = indexedDB.open('REMFiles', 1);

open.onupgradeneeded = function () {
    // The database did not previously exist, so create object stores and indexes.
    const db = open.result;
    const store = db.createObjectStore('files', { keyPath: 'id' });
    store.createIndex('id', 'id', { unique: true });
    store.createIndex('url', 'url', { unique: false });
    store.createIndex('created_at', 'created_at', { unique: false });
};

const _s3Client = {
    getSignedUploadURL: async (params) => {
        const response = await apiCall('GET', '/File/getSignedUploadURL', params);
        return response;
    },

    uploadFile: async (file, fileName) => {
        // upload it as blob, file is file object from input field
        // coreCall = NO BASE URI

        // convert file to blob
        const blobFile = new Blob([file], { type: file.type });

        return await _s3Client.uploadBlob(blobFile, fileName);
    },

    uploadBlob: async (blobFile, fileName) => {
        const signedUploadURLResponse = await _s3Client.getSignedUploadURL({ size: blobFile.size, name: fileName });
        //console.log(signedUploadURLResponse);
        if (!signedUploadURLResponse || !signedUploadURLResponse.status || signedUploadURLResponse.status !== 200 || !signedUploadURLResponse.data || !signedUploadURLResponse.data.uploadUrl) {
            return signedUploadURLResponse;
        }


        // remove form local if exists
        await _s3Client.clearLocalFile({ name: fileName });

        const uploadResponse = await axios.put(signedUploadURLResponse.data.uploadUrl, blobFile, {
            headers: {
                'Content-Type': blobFile.type,
            },
            // not include credentials
            withCredentials: false,
        });

        if(!uploadResponse || !uploadResponse.status || uploadResponse.status !== 200) {
            return uploadResponse;
        }

        return signedUploadURLResponse;
    },

    fileExists: async (args) => {
        // head request to check if file exists
        // args must contain url or fileName, if url is set, fileName is ignored

        if (!args.url && !args.fileName) {
            return Promise.reject('No url or fileName provided');
        }

        if(!args.url) {
            const r = await _s3Client.getSignedDownloadURL({ name: args.fileName });
            if (!r || !r.url) {
                return Promise.resolve(false);
            }

            args.url = r.url;
        }

        try {
            const response = await axios.get(args.url, {
                headers: {
                    // bytes=0-0
                    Range: 'bytes=0-0',
                }
            }); // we should make HEAD, but it's disabled ATM
            return response.status === 206;
        } catch (e) {
            return false;
        }
    },

    clearLocalFile: async (params) => {
        // by name
        const db = open.result;
        const txWrite = db.transaction('files', 'readwrite');
        const store = txWrite.objectStore('files');
        await store.delete(params.name);

    },

    getSignedDownloadURL: async (params) => {
        // Check if the file is stored locally
        const db = open.result;
        const txRead = db.transaction('files', 'readonly');
        const storeRead = txRead.objectStore('files');
        let localFile = storeRead.get(params.name);

        const localResult = await new Promise((resolve) => {
            localFile.onsuccess = () => {
                resolve(localFile.result);
            };

            localFile.onerror = () => {
                resolve(null);
            };
        });

        //console.log(localResult);

        if (localResult) {
            // Check if the file is older than 24 hours
            const now = new Date();
            const createdAt = new Date(localResult.created_at);
            const diff = now.getTime() - createdAt.getTime();
            const diffHours = diff / (1000 * 3600);

            if (diffHours < 24) {
                //console.log('returning local', localResult.url);
                return { url: localResult.url };
            } else {
                //console.log('deleting local', localResult.url);
                const txWrite = db.transaction('files', 'readwrite');
                const store = txWrite.objectStore('files');
                await store.delete(params.name);
            }
        }

        const response = await apiCall('GET', '/File/getSignedDownloadURL', params);

        if (response && response.data && response.data.downloadUrl) {
            // Store locally
            const txWrite = db.transaction('files', 'readwrite');
            const store = txWrite.objectStore('files');

            //console.log('storing locally', response.data.downloadUrl);
            await store.add({ id: params.name, url: response.data.downloadUrl, created_at: new Date().toISOString() });
        }

        return {
            ...response.data,
            url: response.data.downloadUrl,
        };
    },
};

export const s3Client = _s3Client;
